import './style.css';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader"


// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//imported Model
let mixer;
const gltfLoader = new GLTFLoader()
gltfLoader.load("bakedSceneExport33.glb", function (gltf) {
    const robotRoom = gltf.scene;
    robotRoom.rotation.y = 5.2
    robotRoom.position.x = 17
    robotRoom.position.y = -4
    scene.add(robotRoom);
    mixer = new THREE.AnimationMixer(robotRoom);
    const clips = gltf.animations;

    const clip = THREE.AnimationClip.findByName(clips,"animation_0")
    const action = mixer.clipAction(clip);
    action.play()
})

//Fog
//scene.fog = new THREE.Fog( 0x8E2837, 2700, 3000 ); 


//Sizes
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}


window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    const newAspect = sizes.width / sizes.height;

    camera.left = size * newAspect / -2;
    camera.right = size * newAspect / 2;
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);

})

let size = 68;

// Base camera
const aspect = sizes.width / sizes.height;
if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    size = 100;
  }else{
    size = 68;
  }
/* const size = 90; */
let camera = new THREE.OrthographicCamera(
    size * aspect / -2,
    size * aspect / 2,
    size / 2,
    size / -2,
    0.1,
    40000
);

camera.position.x = 0;
camera.position.y = 30;
camera.position.z = 300;
camera.lookAt(0,60,0);



scene.add(camera)


//Orbit Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0,60,0)
controls.update()
controls.enableDamping = false
controls.enableZoom = false
controls.minPolarAngle = 1.6704
controls.maxPolarAngle = 1.6705
controls.enablePan = false

let antid = true;

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    antid = false;
  }else{
    antid = true;
  }

//Renderer Initalize
const renderer = new THREE.WebGLRenderer({
    antialias:antid,
    canvas: canvas,
    alpha:true
})
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding;



//Anim Player
const clock = new THREE.Clock();
function animate() {
    if(mixer)
        mixer.update(clock.getDelta());
}
renderer.setAnimationLoop(animate);


//Time
const tick = () =>
{
    // Update Orbital Controls
    /* controls.update() */

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}
tick()


